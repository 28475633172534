import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const CTABar2 = () => {
  return (
    <section style={{ background: "black" }}>
      <div className="container">
        <div className="row no-gutters call-to-action">
          <div className="col-lg-9">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="call-title">
                <h2
                  className="title title-regular size-xs"
                  style={{ color: "#D9963D" }}
                >
                  Looking For Some Advice?
                </h2>
                <p className="color-light-accent">
                  Get in touch with one of our friendly team members, we can
                  call you to discuss your needs and options.
                </p>
              </div>
            </ScrollAnimation>
          </div>
          <div className="col-lg-3">
            <div className="call-btn">
              <a
                href="tel:+611300906963"
                className="btn-ghost color-light radius-4 border-color-light btn-icon icon-light icon-right size-sm hover-bg-primary hover-border-primary"
                style={{ backgroundColor: "#D9963D", color: "white" }}
              >
                Call Now<i className="fas fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTABar2;
