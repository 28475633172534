import React from "react";
import Slider1 from "components/slider_1";
import CTABar2 from "components/cta_bar_2";
import WhyUsBlock from "components/why_us_block";
import AboutBlock4 from "components/about_block_4";
const HomePage = () => {
  return (
    <>
      {/* HERO BANNER */}
      <Slider1 />

      {/* CALL-US CTA Bar */}
      <CTABar2 />

      {/* About Section */}
      <AboutBlock4 />

      {/* Why us section */}
      <WhyUsBlock />
    </>
  );
};

export default HomePage;
