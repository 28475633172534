import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./styles.css";

const LargeHeroBanner = ({ bgImage, children }) => {
  return (
    <section
      className="bg-common-dark hero-banner"
      data-bg-image={bgImage}
      style={{ minHeight: "75vh" }}
    >
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-12 col-12 pt-4" style={{ marginTop: "10vh" }}>
            <div
              className="calculation-content margin-b-30r md--space-b-70r mt-4"
              style={{ color: "white" }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LargeHeroBanner;
