import React from "react";

import ScrollAnimation from "react-animate-on-scroll";

const WhyUsBlock = () => {
  return (
    <>
      <section className="section-space-default-less30 bg-light-accent100">
        <div className="section-heading text-center heading-dark heading-layout1">
          <div className="w-100 text-center mb-4">
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <h2
                style={{ fontWeight: "300" }}
                className="title title-regular size-xs mt-5"
              >
                Why Us?
              </h2>
            </ScrollAnimation>
            <br />
            <br />
          </div>
          <div style={{ fontWeight: "300" }}>
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <p>
                Finstead Risk Solutions has the experience and reputability of
                an established insurance firm, with the innovation and personal
                involvement that accompanies a younger firm. You can count on
                our experienced team at Finstead Risk Solutions to provide the
                most informed and reliable advice.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <p>
                Founded by highly respected leaders in the insurance broking
                field, our team will quickly identify appropriate insurers for
                your needs. With significant experience arranging holistic
                programs for a wide variety of organisations from the ASX100 to
                SME, our team has developed programs for a vast cross-section of
                Australian and international businesses.
              </p>
            </ScrollAnimation>
            <br />

            <br />
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <p
                style={{ border: "1px solid #D9963D" }}
                className="p-3 pt-5 pb-5"
              >
                We arrange specific solutions for our clients. Each client’s
                risk exposure needs to be considered against its risk tolerance
                and also the best value for their insurance spend.
              </p>
            </ScrollAnimation>
            <br />

            <br />

            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <h3
                style={{
                  fontWeight: "300",
                  textTransform: "none",
                  color: "#D9963D",
                  fontSize: "1.8em",
                  maxWidth: "900px",
                }}
              >
                <strong>
                  Our relationships extend to key international insurance and
                  reinsurance markets, enabling us to solve issues for clients
                  when traditional insurers exit key sectors.
                </strong>
              </h3>
            </ScrollAnimation>
            <br />

            <br />
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
              <p>
                Finstead Risk Solutions has developed a range of technology
                offerings, tailored to each clients specific needs. From white
                label quote/bind/pay solutions, to more advanced direct API
                integrations, we have the capability and experience to deliver
                bespoke customised technology solutions for our clients.
              </p>
            </ScrollAnimation>
            <br />
          </div>

          <div className="container">
            <div style={{ paddingTop: "100px" }}>
              <div className="row mt-5 mb-5">
                <div className="col-12 col-xs-12 col-sm-6">
                  <ScrollAnimation
                    animateIn="fadeInLeft"
                    animateOut="fadeOutLeft"
                    animateOnce
                  >
                    <img
                      src="/img/banner/opera-house.jpg"
                      style={{
                        width: "100%",
                        margin: "0px auto",
                        borderRadius: "15px",
                        filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.2))",
                        // position: "relative",
                        // left: "-50px",
                      }}
                    ></img>
                  </ScrollAnimation>
                </div>
                <div
                  className="col-12 col-xs-12 col-sm-6"
                  style={{ textAlign: "left" }}
                >
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    animateOut="fadeOutRight"
                    animateOnce
                  >
                    <h2
                      style={{ fontWeight: "300" }}
                      className="fs-section-header  pl-4"
                    >
                      Our Vision
                    </h2>
                    <p
                      className="lead m-0 mb-5 mt-3 w-100 pl-4"
                      style={{
                        fontSize: "1.3em",
                        padding: "none",
                        margin: "none",
                      }}
                    >
                      We are the partner of choice for Australian businesses and
                      advisers. We take the time to understand our clients’
                      businesses and are committed to bringing the best in
                      service, experience and technologies through our best in
                      class team.
                    </p>
                    <a
                      className="btn btn-sm btn-fill mb-5  ml-4"
                      href="/services"
                    >
                      Go To Our Services
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyUsBlock;
