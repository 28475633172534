import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import LargeHeroBanner from "components/large_hero_banner";

const Slider1 = () => {
  return (
    <div id="header-area-space" className="slider-area bg-light-primary">
      <LargeHeroBanner bgImage={"/img/slider/slider1-1.jpg"}>
        <h2>
          <strong>Finstead Risk Solutions</strong>
        </h2>
        <h2>Your Partners In</h2>
        <h2>Insurance And Risk</h2>
        <br />
        {/* <p className="lead">
          We're here to help you make the right choice for your business.
        </p> */}
        {/* <a
          href="/contact"
          className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
        >
          Call Now
          <i className="fas fa-angle-right" />
        </a> */}
      </LargeHeroBanner>
    </div>
  );
};

export default Slider1;
