import React from "react";
import "./styles.css";
import ScrollAnimation from "react-animate-on-scroll";

const AboutBlock4 = () => {
  return (
    <>
      <section className="section-space-default-less38 bg-light-primary100">
        <div className="container">
          <div className="section-heading text-left heading-dark heading-layout1">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <h3
                style={{
                  fontWeight: "300",
                  textTransform: "none",
                  color: "#D9963D",
                  fontSize: "1.8em",
                }}
                className=""
              >
                <strong>Finstead Risk Solutions</strong> – a new insurance
                broking organisation servicing the needs of modern businesses.
                We’re here to help businesses manage the unexpected.
              </h3>
            </ScrollAnimation>
          </div>
          <div className="w-100 text-left">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <p className="lead" style={{ fontSize: "1.3em" }}>
                Our team specialises in key areas of insurance, including:
              </p>
            </ScrollAnimation>
            <br />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          src="/icons/005-risk.png"
                          style={{ maxWidth: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="/services/asset-finance">
                          Professional &amp; Financial Risk
                        </a>
                      </h4>
                      <p className="size-xs">
                        This is an area in which policies are unique to each
                        insurer. Accordingly, it is essential that businesses
                        arrange a policy that addresses their risks. Our
                        specialists have vast experience in arranging solutions
                        for a wide range of circumstances, such as listing on
                        securities exchanges, capital raisings, management
                        exposure and the increasing risks associated with modern
                        business.
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          srcSet="/icons/006-networking.png 2x"
                          style={{ width: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="/services/commercial-property">
                          Group &amp; Affinity
                        </a>
                      </h4>
                      <p className="size-xs">
                        Delivering efficiencies to large buying groups, our
                        unique information technology solutions dove-tail with
                        our significant experience in arranging group facilities
                        with appropriate insurers. Matching insurer appetite to
                        on-line solutions provides groups with member benefit
                        and efficiency for each client within the group.
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          srcSet="/icons/004-chat.png 2x"
                          style={{ width: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="/services/business-loans/">Risk Advisory</a>
                      </h4>
                      <p className="size-xs">
                        Risk management starts with risk identification. Our
                        services include the provision of risk reports to assist
                        clients in their identification, mitigation, treatment
                        and in identifying appropriate risk transfer.
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          srcSet="/icons/003-corporation.png 2x"
                          style={{ width: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="/services/business-loans/">
                          Corporate and Commercial
                        </a>
                      </h4>
                      <p className="size-xs">
                        Programmes for large businesses need to address the
                        broad exposures that modern businesses face. Our
                        specialists have significant experience in arranging
                        cover to dove-tail with each organisation’s risk
                        appetite.
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          srcSet="/icons/001-network.png 2x"
                          style={{ width: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="#">SME</a>
                      </h4>
                      <p className="size-xs">
                        Insurance buying is often underestimated by SME
                        businesses. We represent your interests, firstly in
                        arranging appropriate risk transfer solutions and
                        follow-through to assisting with claims negotiation and
                        settlement.
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="row">
                  <div className="col-xs-12 xol-sm-12 col-md-3 mb-4 text-center">
                    <ScrollAnimation animateIn="fadeInUp">
                      <div style={{ width: "60px", maxHeight: "80px" }}>
                        <img
                          srcSet="/icons/002-agreement.png 2x"
                          width="60px"
                          height="auto"
                          style={{ width: "40px", maxWidth: "40px" }}
                        />
                      </div>
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 xol-sm-12 col-md-9">
                    <ScrollAnimation animateIn="fadeInUp">
                      <h4 className="title title-medium color-dark-accent hover-color-accent">
                        <a href="/services/business-loans/">M&amp;A</a>
                      </h4>
                      <p className="size-xs">
                        Direct access to key advisors is critical in the M&amp;A
                        environment. We partner with our clients to provide
                        appropriate risk advice and have extensive experience in
                        aspects such as:
                        <ul
                          className="services-list"
                          style={{ lineHeight: "25px" }}
                        >
                          <li>Insurance due diligence</li>
                          <li>Benchmarking</li>
                          <li>Warranty &amp; Indemnity Insurance</li>
                        </ul>
                      </p>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBlock4;
